import React from 'react';
import styled from 'styled-components';
import { WrapMax } from '$components/Wraps';
import Column from './Column';
import { BlockTwoColumnsProps } from './types';
import FadeInSection from '$components/FadeInSection';
import Markdown from '$components/Markdown';

const Row = styled.div`
  display: grid;
  grid-gap: 1em;
  max-width: 61.625em;
  justify-self: center;
  align-items: start;
  margin: 0 auto;

  @media screen and (min-width: 860px) {
    grid-gap: 4em 3em;
    grid-template-columns: 1fr 1fr;
  }
`;

const BlockTwoColumns: React.FC<BlockTwoColumnsProps> = ({ column1, column2 }) => (
  <FadeInSection>
    <WrapMax>
      <Row>
        <Column>
          <Markdown content={column1} />
        </Column>
        <Column>
          <Markdown content={column2} />
        </Column>
      </Row>
    </WrapMax>
  </FadeInSection>
);

export default BlockTwoColumns;
