import React from 'react';
import styled from 'styled-components';

const StyledColumn = styled.p`
  margin: 0;
  color: var(--color-body-text);
  line-height: 1.75;

  @media screen and (min-width: 860px) {
    font-size: 1em;
  }
`;

const Column: React.FC = ({ children }) => <StyledColumn>{children}</StyledColumn>;

export default Column;
